import {useTranslation} from "react-i18next";
import {fuel_chamber_tier_1, fuel_chamber_tier_2, fuel_chamber_tier_3} from "../../../calculators/values/gen_constants";
import ValueTable from "./value_table";
import React from "react";


function FuelChamberValueTable() {
    const { t } = useTranslation('values');

    const fuel_chamber_headers = [
        t("generators.fuel_chamber"),
        t("generators.heat")
    ];

    const fuel_chamber_data = [
        [t("generators.fuel_chamber_tier_1"), fuel_chamber_tier_1.heat],
        [t("generators.fuel_chamber_tier_2"), fuel_chamber_tier_2.heat],
        [t("generators.fuel_chamber_tier_3"), fuel_chamber_tier_3.heat],
    ];

    return <ValueTable
        title={t("generators.fuel_chamber")}
        headers={fuel_chamber_headers}
        data={fuel_chamber_data}
    />
}

export default FuelChamberValueTable;
