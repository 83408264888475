import React, {useState} from "react";
import {Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useTheme} from "../theme/theme_context";
import {prettyNumber} from "../../utilities/pretty_number";
import {value_replace_zero, value_zero_replacement} from "./const_values";
import {GlobalMarketValues} from "./interfaces/global_market_values"; // Import the interface

interface MarketValueTableProps {
    title: string;
    headers: string[];
    data: GlobalMarketValues[];
    check_for_images?: boolean;
    links?: string[];
}

type SortConfig = {
    key: keyof GlobalMarketValues | null;
    direction: "ascending" | "descending";
};

function MarketValueTable(
    {
        title,
        headers,
        data,
        check_for_images = false,
        links = []
    }: MarketValueTableProps) {
    const navigate = useNavigate();
    const {theme} = useTheme();
    const [sortConfig, setSortConfig] = useState<SortConfig>({key: null, direction: "ascending"});

    const sortedData = React.useMemo(() => {
        if (sortConfig.key === null) {
            return data;
        }
        return [...data].sort((a, b) => {
            const aValue = a[sortConfig.key!];
            const bValue = b[sortConfig.key!];
            if (typeof aValue === "number" && typeof bValue === "number") {
                return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
            }
            if (typeof aValue === "string" && typeof bValue === "string") {
                return sortConfig.direction === "ascending"
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }
            return 0;
        });
    }, [data, sortConfig]);

    const requestSort = (key: keyof GlobalMarketValues) => {
        let direction: "ascending" | "descending" = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({key, direction});
    };

    const renderCell = (cell: string | number, key: keyof GlobalMarketValues) => {
        if (check_for_images && key === "source_asset" && typeof cell === "string") {
            return (
                <td key={key} className="value-table-image-td">
                    <img src={cell} alt="table item" className="value-table-image"/>
                </td>
            );
        }
        if (value_replace_zero && typeof cell === "number" && cell === 0) {
            return <td key={key}>{value_zero_replacement}</td>;
        }
        if (typeof cell === "number") {
            return <td key={key}>{prettyNumber(cell)}</td>;
        }
        return <td key={key}>{cell}</td>;
    };

    const handleRowClick = (rowIndex: number) => {
        if (links[rowIndex]) {
            navigate(links[rowIndex]); // Use navigate for client-side routing
        }
    };

    return (
        <div>
            <h4>{title}</h4>
            <Table striped bordered hover responsive variant={theme === "dark" ? "dark" : ""}>
                <thead>
                <tr className="values-row-even">
                    {headers.map((header: string, index: number) => {
                        // Map header to corresponding key in GlobalMarketValues
                        // Ensure the order matches the headers array
                        const keyMap: { [key: number]: keyof GlobalMarketValues } = {
                            0: "source_asset",
                            1: "name",
                            2: "price",
                            3: "quantity",
                            4: "timestamp"
                        };
                        const key = keyMap[index];
                        return (
                            <th
                                key={index}
                                onClick={() => requestSort(key)}
                                style={{cursor: "pointer"}}
                            >
                                {header}
                                {sortConfig.key === key ? (
                                    sortConfig.direction === "ascending" ? " ↑" : " ↓"
                                ) : null}
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {sortedData.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        className={(rowIndex + 1) % 2 === 0 ? "values-row-even" : "values-row-odd"}
                        onClick={() => handleRowClick(rowIndex)}
                    >
                        {headers.map((_, cellIndex) => {
                            const keyMap: { [key: number]: keyof GlobalMarketValues } = {
                                0: "source_asset",
                                1: "name",
                                2: "price",
                                3: "quantity",
                                4: "timestamp"
                            };
                            const key = keyMap[cellIndex];
                            const cell = row[key];
                            return renderCell(cell, key);
                        })}
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
}

export default MarketValueTable;
