import {Navbar, Nav, Image, NavDropdown} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import EnImg from '../../assets/img/flags/en_flag.png';
import FrImg from '../../assets/img/flags/fr_flag.png';
import GitHub from '../../assets/img/logos/github_logo.png';
import LogoImg from '../../assets/img/logos/logo.png';
import './Header.css'
import '../../dark_mode.css'
import {
    armor_optimisation_url_part,
    capship_calc_url_part, changelog_url_part, github_link, market_url_part,
    ship_calc_url_part,
    thruster_optimisation_url_part, values_url_part
} from "../global_values";
import {useTheme} from "../theme/theme_context";
import React, {useEffect} from "react";

function Header() {
    const { t, i18n } = useTranslation('header');

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    const { theme, toggleTheme } = useTheme();

    useEffect(() => {
        document.body.className = theme;
    }, [theme]);

    return (
        <Navbar collapseOnSelect expand="lg" className="navbar">
            <Link to="/">
                <Navbar.Brand className="navbar-brand">
                    <Image src={LogoImg} className="logo" />
                </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <div className="nav-links-container">
                    <Nav className="mr-auto">
                        <Nav.Link as={HashLink} to="/">{t('navbar.home')}</Nav.Link>
                        <NavDropdown title={t("navbar.about.title")}>
                            <NavDropdown.Item as={HashLink} to={"/" + changelog_url_part}>{t('navbar.about.changelog')}</NavDropdown.Item>
                            <NavDropdown.Item as={HashLink} to={"/" + values_url_part}>{t('navbar.about.values')}</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={t("navbar.calculators.title")}>
                            <NavDropdown.Item as={HashLink} to={"/" + ship_calc_url_part}>{t('navbar.calculators.ship_calculator')}</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={t("navbar.prices.title")}>
                            <NavDropdown.Item as={HashLink} to={"/" + capship_calc_url_part}>{t('navbar.prices.capship_price')}</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={t("navbar.optimisation.title")}>
                            <NavDropdown.Item as={HashLink} to={"/" + armor_optimisation_url_part}>{t('navbar.optimisation.armor_optimisation')}</NavDropdown.Item>
                            <NavDropdown.Item as={HashLink} to={"/" + thruster_optimisation_url_part}>{t('navbar.optimisation.thruster_optimisation')}</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={t("navbar.market.title")}>
                            <NavDropdown.Item as={HashLink} to={"/" + market_url_part}>{t('navbar.market.title')}</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </div>
                <Nav className="ml-auto social-links">
                    <div className="social-links">
                        <Image onClick={() => changeLanguage('en')} src={EnImg}/>
                        <Image onClick={() => changeLanguage('fr')} src={FrImg}/>
                        <span onClick={toggleTheme} className={"theme-button"}>
                            {
                                (theme === 'light') ?
                                    <i className="fa-solid fa-moon theme-button-icon"></i> :
                                    <i className="fa-solid fa-sun theme-button-icon"></i>
                            }
                        </span>
                        <Image onClick={() => window.open(github_link, "_blank")} src={GitHub}/>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header;
