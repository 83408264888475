import {tier1_enhancer, tier2_enhancer, tier3_enhancer} from "../../../calculators/values/gen_constants";
import {useTranslation} from "react-i18next";
import ValueTable from "./value_table";
import React from "react";

function EnhancersValueTable() {
    const { t } = useTranslation('values');

    const enhancers_headers = [
        t("generators.enhancers"),
        t("generators.electricity_tokens"),
        t("generators.heat_tokens")
    ];

    const enhancers_data = [
        [t("generators.enhancer_tier_1"), tier1_enhancer.electricity_tokens, tier1_enhancer.heat_tokens],
        [t("generators.enhancer_tier_2"), tier2_enhancer.electricity_tokens, tier2_enhancer.heat_tokens],
        [t("generators.enhancer_tier_3"), tier3_enhancer.electricity_tokens, tier3_enhancer.heat_tokens],
    ];

    return <ValueTable
        title={t("generators.enhancers")}
        headers={enhancers_headers}
        data={enhancers_data}
    />
}

export default EnhancersValueTable;
