import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import i18n from "i18next";
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.css';
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import 'bootstrap/dist/css/bootstrap.css';
import {ThemeProvider} from "./components/theme/theme_context";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "en",
        fallbackLng: "en",
        saveMissing: true,
        missingKeyHandler: function(lngs: readonly string[], ns: string, key: string, fallbackValue: string) {
            // log the missing keys
            console.error(`Missing key: ${key}, Namespace: ${ns}, Languages: ${lngs}`);
        },
        ns: ["header", "footer", "homepage", "cap_ship_calc", "ship_calc", "tips", "changelog", "known_issues",
            "todolist", "thruster_optimisation", "values", "armor_optimisation", "market"],
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        }
    });
i18n.on('failedLoading', function(lng, ns, msg) {
    console.error(`Failed to load ${lng} namespace: ${ns}`);
    console.error(msg);
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider>
            <App />
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
