import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { prettyNumber } from "../../../../utilities/pretty_number";
import { value_replace_zero, value_zero_replacement } from "../../const_values";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../theme/theme_context";
import "./value_table.css"


function ValueTable(
    {
        title,
        headers,
        data,
        check_for_images = false,
        links = []
    }:{
        title: string,
        headers: string[],
        data: (string|number)[][]
        check_for_images?: boolean
        links?: string[]
    }) {
    const navigate = useNavigate();
    const { theme } = useTheme();
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

    const sortedData = React.useMemo(() => {
        let sortableData = [...data];
        if (sortConfig.key !== null) {
            sortableData.sort((a: (string|number)[], b: (string|number)[]) => {
                if (sortConfig.key !== null) {
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const requestSort = (key: any) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    const isImagePath = (cell: string | number): boolean => {
        return typeof cell === "string" && cell.startsWith("/assets/img/");
    };

    function td_image(cell: string, cellIndex: number) {
        return <td key={cellIndex} className={"value-table-image-td"}>
            <img src={cell as string} alt="table item" className={"value-table-image"}/>
        </td>
    }

    function td_value(cell: string | number, cellIndex: number) {
        return <td key={cellIndex}>
            {
                value_replace_zero
                ? (cellIndex !== 0 && cell === 0
                    ? value_zero_replacement
                    : prettyNumber(cell))
                : prettyNumber(cell)
            }
        </td>
    }

    const handleRowClick = (rowIndex: number) => {
        if (links[rowIndex]) {
            navigate(links[rowIndex]);  // Use navigate for client-side routing
        }
    };

    return (
        <div>
            <h4>{title}</h4>
            <Table striped bordered hover responsive variant={theme === "dark" ? "dark" : ""}>
                <thead>
                <tr className={"values-row-even"}>
                    {headers.map((header: string, index: number) => (
                        <th
                            key={index}
                            onClick={() => requestSort(index)}
                            style={{ cursor: "pointer" }}
                        >
                            {header}
                            {sortConfig.key === index ? (
                                sortConfig.direction === "ascending" ? " ↑" : " ↓"
                            ) : null}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {sortedData.map((row, rowIndex) => (
                    <tr
                        key={rowIndex}
                        className={((rowIndex + 1) % 2 === 0) ? "values-row-even" : "values-row-odd"}
                        onClick={() => handleRowClick(rowIndex)}
                    >
                        {row.map((cell: string|number, cellIndex: number) => (
                            check_for_images && isImagePath(cell) ? (
                                td_image(cell as string, cellIndex)
                            ) : (
                                td_value(cell, cellIndex)
                            )
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
}

export default ValueTable;
