import './nopage.css'
import {Col, Container, Image, Row} from "react-bootstrap";
import Err404 from '../../assets/img/misc/Error-404-page.png'

function NoPage() {
    return <div>
        <Container>
            <Row>
                <Col className={"nopage-container"}>
                    <Image src={Err404} className="nopage-img" />
                </Col>
            </Row>
        </Container>
    </div>
}

export default NoPage;
