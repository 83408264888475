import { Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { backend_url } from "../global_values";
import { MarketData } from "./interfaces/market_data";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isOlderThan } from "../../utilities/is_older_than";
import { market_no_longer_for_sale_metric, market_no_longer_for_sale_value } from "./const_values";
import { GlobalMarketValues } from "./interfaces/global_market_values";
import MarketValueTable from "./market_values_table";
import { useTranslation } from "react-i18next";
import { all_resources, material } from "../calculators/values/armor_constants";
import { localeMap } from "../../utilities/locales_map";
import 'dayjs/locale/en';
import 'dayjs/locale/fr';

dayjs.extend(utc);
dayjs.extend(relativeTime);

function GlobalMarket() {
    const { t, i18n } = useTranslation('market');
    const dayjsLocale = localeMap[i18n.language] || i18n.language;

    const resources = all_resources;
    const market_name = "origin";
    const market_url = backend_url + "/" + market_name;
    const [marketData, setMarketData] = useState<MarketData>({});
    const [marketValues, setMarketValues] = useState<GlobalMarketValues[]>([]);
    const [marketLinks, setMarketLinks] = useState<string[]>([]);

    const market_headers = [
        t('main.headers.icon'),
        t('main.headers.name'),
        t('main.headers.price'),
        t('main.headers.quantity'),
        t('main.headers.availability'),
    ];

    useEffect(() => {
        dayjs.locale(dayjsLocale);
    }, [dayjsLocale]);

    useEffect(() => {
        function fetchMarketData() {
            fetch(market_url)
                .then(response => response.json())
                .then(fetchedData => {
                    setMarketData(fetchedData);
                })
                .catch(error => console.error('Error:', error));
        }
        fetchMarketData();
        const interval = setInterval(() => {
            fetchMarketData();
        }, 60000);
        return () => clearInterval(interval);
    }, [market_url]);

    function no_resources_available(resource: material, last_available: string): GlobalMarketValues {
        return {
            source_asset: resource.source_asset,
            name: resource.name,
            price: 0,
            quantity: 0,
            timestamp: last_available,
        };
    }

    useEffect(() => {
        let market_links: string[] = [];
        const market_values = resources.map(resource => {
            const resourceKey = resource.name.toLowerCase();
            const marketInfo = marketData[resourceKey];
            market_links.push(market_name + "/" + resourceKey);
            if (marketInfo) {
                const localTimeString = dayjs.utc(marketInfo.timestamp).local().fromNow();
                if (isOlderThan(marketInfo.timestamp, market_no_longer_for_sale_metric, market_no_longer_for_sale_value)) {
                    return no_resources_available(resource, t("resource.last_available", { time: localTimeString }));
                }
                return {
                    source_asset: resource.source_asset,
                    name: resource.name,
                    price: marketInfo.price,
                    quantity: marketInfo.quantity,
                    timestamp: t('resource.available'),
                };
            } else {
                return no_resources_available(resource, t('resource.not_available'));
            }
        });
        setMarketValues(market_values);
        setMarketLinks(market_links);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketData, t, dayjsLocale]);

    return (
        <Container>
            <Row>
                <h3>{t('main.title')}</h3>
            </Row>
            <Row>
                <p>{t('main.help')}</p>
            </Row>
            <Row>
                <MarketValueTable
                    title={"Origin"}
                    headers={market_headers}
                    data={marketValues}
                    check_for_images={true}
                    links={marketLinks}
                />
            </Row>
        </Container>
    );
}

export default GlobalMarket;
