// external links
export const discord_link = "https://discord.gg/ouroboros-unlimited-752572414713397298"
export const github_link = "https://github.com/HappyTrigger42/starbase-tools"

// Starbase values
export const build_count = 922
export const stack_value = 1728

// backend url
export const backend_url = "https://public.starbase-tools-backend.com"

// urls
export const self_url = "https://starbase-tools.com"
export const ship_calc_url_part = "ship_calculator"
export const ship_calc_url = self_url + "/" + ship_calc_url_part
export const capship_calc_url_part = "capship_price_calc"
export const capship_calc_url = self_url + "/" + capship_calc_url_part
export const thruster_optimisation_url_part = "thruster_optimisation"
export const thruster_optimisation_url = self_url + "/" + thruster_optimisation_url_part
export const changelog_url_part = "changelog"
export const values_url_part = "values"
export const values_url = self_url + "/" + values_url_part
export const logo_attribution_url = "https://www.flaticon.com/free-icons/calculator"
export const armor_optimisation_url_part = "armor_optimisation"
export const armor_optimisation_url = self_url + "/" + armor_optimisation_url_part
export const market_url_part = "market"
export const market_url = self_url + "/" + market_url_part

// website values
export const creator_name = "HappyTrigger"
