import {useTranslation} from "react-i18next";
import {bat_capacity} from "../../../calculators/values/misc_constants";
import ValueTable from "./value_table";
import React from "react";

function ElectricityValueTable() {
    const { t } = useTranslation('values');

    const electricity_headers = [
        t("electricity.component"),
        t("electricity.electricity_storage")
    ];

    const electricity_data = [
        [t("electricity.battery"), bat_capacity],
    ];

    return <ValueTable
        title={t("electricity.electricity")}
        headers={electricity_headers}
        data={electricity_data}/>
}

export default ElectricityValueTable;
