import {useTranslation} from "react-i18next";
import {
    tier_1_electricity_converter, tier_1_propellant_converter,
    tier_2_electricity_converter, tier_2_propellant_converter,
    tier_3_electricity_converter, tier_3_propellant_converter
} from "../../../calculators/values/thruster_constants";
import ValueTable from "./value_table";
import React from "react";


function ConvertersValueTable() {
    const { t } = useTranslation('values');

    const converters_headers = [
        t("thrusters.converters"),
        t("thrusters.electricity_conversion"),
        t("thrusters.propellant_conversion"),
        t("mass"),
        t("volume")
    ];

    const converters_data = [
        [t("thrusters.electricity_converter_tier_1"), tier_1_electricity_converter.converter, 0, tier_1_electricity_converter.weight, tier_1_electricity_converter.voxel_volume],
        [t("thrusters.electricity_converter_tier_2"), tier_2_electricity_converter.converter, 0, tier_2_electricity_converter.weight, tier_2_electricity_converter.voxel_volume],
        [t("thrusters.electricity_converter_tier_3"), tier_3_electricity_converter.converter, 0, tier_3_electricity_converter.weight, tier_3_electricity_converter.voxel_volume],
        [t("thrusters.propellant_converter_tier_1"), 0, tier_1_propellant_converter.converter, tier_1_propellant_converter.weight, tier_1_propellant_converter.voxel_volume],
        [t("thrusters.propellant_converter_tier_2"), 0, tier_2_propellant_converter.converter, tier_2_propellant_converter.weight, tier_2_propellant_converter.voxel_volume],
        [t("thrusters.propellant_converter_tier_3"), 0, tier_3_propellant_converter.converter, tier_3_propellant_converter.weight, tier_3_propellant_converter.voxel_volume]
    ];

    return <ValueTable
        title={t("thrusters.converters")}
        headers={converters_headers}
        data={converters_data}
    />
}

export default ConvertersValueTable;
