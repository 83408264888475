import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { prettyNumber } from "../../../../utilities/pretty_number";
import { value_replace_zero, value_zero_replacement } from "../../const_values";
import { resourcesValueProp } from "./resource_value_prop";
import { stack_value } from "../../../global_values";
import { useTheme } from "../../../theme/theme_context";

function ResourceTable(
    {
        title,
        headers,
        data
    }:{
        title: string,
        headers: string[],
        data: resourcesValueProp[]
    }) {
    const { theme } = useTheme();
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

    const sortedData = React.useMemo(() => {
        let sortableData = [...data];
        if (sortConfig.key !== null) {
            sortableData.sort((a, b) => {
                if (sortConfig.key !== null) {

                    if (a.value[sortConfig.key] < b.value[sortConfig.key]) {
                        return sortConfig.direction === "ascending" ? -1 : 1;
                    }
                    if (a.value[sortConfig.key] > b.value[sortConfig.key]) {
                        return sortConfig.direction === "ascending" ? 1 : -1;
                    }
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const requestSort = (key: any) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    return (
        <div>
            <h4>{title}</h4>
            <Table striped bordered hover responsive variant={theme === "dark" ? "dark" : ""}>
                <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th
                            key={index}
                            onClick={() => requestSort(header)}
                            style={{ cursor: "pointer" }}
                        >
                            {header}
                            {sortConfig.key === header ? (
                                sortConfig.direction === "ascending" ? "↑" : "↓"
                            ) : null}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {sortedData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td key={rowIndex + row.name}>{row.name}</td>
                        {Object.entries(row.value).map(([key, value], index) => (
                            <td key={rowIndex + " " + index}>
                                {
                                    value_replace_zero ?
                                        ((index !== 0 && value === 0) ? value_zero_replacement : prettyNumber(value / stack_value))
                                        : prettyNumber(value / stack_value)
                                }
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
}

export default ResourceTable;
