import ValueTable from "./value_table";
import {useTranslation} from "react-i18next";
import {materials} from "../../../calculators/values/armor_constants";


function ArmorValueTable() {
    const { t } = useTranslation('values');

    const armor_headers = [
        t("armor.name"),
        t("armor.armor"),
        t("armor.density"),
        t("armor.structural_durability"),
        t("armor.corrosion_resistance"),
    ];

    const armor_values = materials.map(material => {
        return [
            material.name,
            material.armor,
            material.density,
            material.structural_durability,
            material.corrosion_resistance
        ];
    });

    return <ValueTable
        title={t("armor.material_values")}
        headers={armor_headers}
        data={armor_values}
    />
}

export default ArmorValueTable;

