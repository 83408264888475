import {useTranslation} from "react-i18next";
import {
    box_thruster_combustion_chamber,
    box_thruster_tier_1_body,
    box_thruster_tier_1_nozzle,
    box_thruster_tier_2_body, box_thruster_tier_2_nozzle,
    box_thruster_tier_3_body, box_thruster_tier_3_nozzle,
    maneuver_thruster_tier_1,
    maneuver_thruster_tier_2,
    maneuver_thruster_tier_3, plasma_thruster_body, plasma_thruster_nozzle, plasma_thruster_ring,
    triangle_thruster_combustion_chamber,
    triangle_thruster_tier_1_body,
    triangle_thruster_tier_1_nozzle,
    triangle_thruster_tier_2_body,
    triangle_thruster_tier_2_nozzle,
    triangle_thruster_tier_3_body,
    triangle_thruster_tier_3_nozzle
} from "../../../calculators/values/thruster_constants";
import ValueTable from "./value_table";
import React from "react";


function ThrustersValueTable() {
    const { t } = useTranslation('values');

    const thrusters_headers = [
        t("thrusters.thrusters"),
        t("thrusters.thrust"),
        t("mass"),
        t("volume"),
        t("thrusters.electricity_usage"),
        t("thrusters.propellant_usage"),
    ];

    const thrusters_data = [
        [t("thrusters.maneuver_tier_1"), maneuver_thruster_tier_1.thrust, maneuver_thruster_tier_1.weight, maneuver_thruster_tier_1.voxel_volume, maneuver_thruster_tier_1.electricity_consumption, maneuver_thruster_tier_1.propellant_consumption],
        [t("thrusters.maneuver_tier_2"), maneuver_thruster_tier_2.thrust, maneuver_thruster_tier_2.weight, maneuver_thruster_tier_2.voxel_volume, maneuver_thruster_tier_2.electricity_consumption, maneuver_thruster_tier_2.propellant_consumption],
        [t("thrusters.maneuver_tier_3"), maneuver_thruster_tier_3.thrust, maneuver_thruster_tier_3.weight, maneuver_thruster_tier_3.voxel_volume, maneuver_thruster_tier_3.electricity_consumption, maneuver_thruster_tier_3.propellant_consumption],
        [t("thrusters.triangle_combustion_chamber"), 0, triangle_thruster_combustion_chamber.weight, triangle_thruster_combustion_chamber.voxel_volume, 0, 0],
        [t("thrusters.triangle_tier_1"), triangle_thruster_tier_1_body.thrust, triangle_thruster_tier_1_body.weight, triangle_thruster_tier_1_body.voxel_volume, triangle_thruster_tier_1_body.electricity_consumption, triangle_thruster_tier_1_body.propellant_consumption],
        [t("thrusters.triangle_tier_2"), triangle_thruster_tier_2_body.thrust, triangle_thruster_tier_2_body.weight, triangle_thruster_tier_2_body.voxel_volume, triangle_thruster_tier_2_body.electricity_consumption, triangle_thruster_tier_2_body.propellant_consumption],
        [t("thrusters.triangle_tier_3"), triangle_thruster_tier_3_body.thrust, triangle_thruster_tier_3_body.weight, triangle_thruster_tier_3_body.voxel_volume, triangle_thruster_tier_3_body.electricity_consumption, triangle_thruster_tier_3_body.propellant_consumption],
        [t("thrusters.triangle_tier_1_nozzle"), triangle_thruster_tier_1_nozzle.thrust, triangle_thruster_tier_1_nozzle.weight, triangle_thruster_tier_1_nozzle.voxel_volume, 0, 0],
        [t("thrusters.triangle_tier_2_nozzle"), triangle_thruster_tier_2_nozzle.thrust, triangle_thruster_tier_2_nozzle.weight, triangle_thruster_tier_2_nozzle.voxel_volume, 0, 0],
        [t("thrusters.triangle_tier_3_nozzle"), triangle_thruster_tier_3_nozzle.thrust, triangle_thruster_tier_3_nozzle.weight, triangle_thruster_tier_3_nozzle.voxel_volume, 0, 0],
        [t("thrusters.box_combustion_chamber"), 0, box_thruster_combustion_chamber.weight, box_thruster_combustion_chamber.voxel_volume, 0, 0],
        [t("thrusters.box_tier_1"), box_thruster_tier_1_body.thrust, box_thruster_tier_1_body.weight, box_thruster_tier_1_body.voxel_volume, box_thruster_tier_1_body.electricity_consumption, box_thruster_tier_1_body.propellant_consumption],
        [t("thrusters.box_tier_2"), box_thruster_tier_2_body.thrust, box_thruster_tier_2_body.weight, box_thruster_tier_2_body.voxel_volume, box_thruster_tier_2_body.electricity_consumption, box_thruster_tier_2_body.propellant_consumption],
        [t("thrusters.box_tier_3"), box_thruster_tier_3_body.thrust, box_thruster_tier_3_body.weight, box_thruster_tier_3_body.voxel_volume, box_thruster_tier_3_body.electricity_consumption, box_thruster_tier_3_body.propellant_consumption],
        [t("thrusters.box_tier_1_nozzle"), box_thruster_tier_1_nozzle.thrust, box_thruster_tier_1_nozzle.weight, box_thruster_tier_1_nozzle.voxel_volume, 0, 0],
        [t("thrusters.box_tier_2_nozzle"), box_thruster_tier_2_nozzle.thrust, box_thruster_tier_2_nozzle.weight, box_thruster_tier_2_nozzle.voxel_volume, 0, 0],
        [t("thrusters.box_tier_3_nozzle"), box_thruster_tier_3_nozzle.thrust, box_thruster_tier_3_nozzle.weight, box_thruster_tier_3_nozzle.voxel_volume, 0, 0],
        [t("thrusters.plasma_thruster"), plasma_thruster_body.thrust, plasma_thruster_body.weight, plasma_thruster_body.voxel_volume, plasma_thruster_body.electricity_consumption, plasma_thruster_body.propellant_consumption],
        [t("thrusters.plasma_ring"), plasma_thruster_ring.thrust, plasma_thruster_ring.weight, plasma_thruster_ring.voxel_volume, plasma_thruster_ring.electricity_consumption, plasma_thruster_ring.propellant_consumption],
        [t("thrusters.plasma_nozzle"), plasma_thruster_nozzle.thrust, plasma_thruster_nozzle.weight, plasma_thruster_nozzle.voxel_volume, plasma_thruster_nozzle.electricity_consumption, plasma_thruster_nozzle.propellant_consumption],
    ];

    return <ValueTable
        title={t("thrusters.thrusters")}
        headers={thrusters_headers}
        data={thrusters_data}
    />
}

export default ThrustersValueTable;
