import React from "react";
import {Button, ButtonGroup, Col, Row} from "react-bootstrap";


export function on_off(title: string, yes: string, no: string, value: boolean, setter: React.Dispatch<React.SetStateAction<boolean>>, css_prefix: string) {
    return <Row className={"card-attribute"}>
        <Col xs={8}>
            <div className={"card-attribute-text"}>{title}</div>
        </Col>
        <Col xs={4}>
            <ButtonGroup className={"thruster-density-selection"}>
                <Button
                    variant={value ? "primary" : "secondary"}
                    onClick={() => setter(true)}
                    className={css_prefix + "-density-selection-button"}>
                    {yes}
                </Button>
                <Button
                    variant={!value ? "primary" : "secondary"}
                    onClick={() => setter(false)}
                    className={css_prefix + "-density-selection-button"}>
                    {no}
                </Button>
            </ButtonGroup>
        </Col>
    </Row>
}
