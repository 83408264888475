import './homepage.css';
import {Button, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {build_count, discord_link} from "../global_values";
import Back4 from "../../assets/img/screenshots/back_4.webp";
import Back5 from "../../assets/img/screenshots/back_5.webp";
import Back6 from "../../assets/img/screenshots/back_6.webp";
import Back7 from "../../assets/img/screenshots/back_7.webp";
import Back8 from "../../assets/img/screenshots/back_8.webp";
import Back9 from "../../assets/img/screenshots/back_9.webp";
import Back12 from "../../assets/img/screenshots/back_12.webp";
import Back13 from "../../assets/img/screenshots/back_13.webp";
import Back14 from "../../assets/img/screenshots/back_14.webp";
import Back15 from "../../assets/img/screenshots/back_15.webp";
import Back16 from "../../assets/img/screenshots/back_16.webp";
import Back18 from "../../assets/img/screenshots/back_18.webp";
import Back19 from "../../assets/img/screenshots/back_19.webp";
import Back20 from "../../assets/img/screenshots/back_20.webp";


import {useEffect, useState} from "react";

function Homepage () {
    const { t } = useTranslation('homepage');
    const images = [Back4, Back5, Back6, Back7, Back8, Back9, Back12, Back13, Back14, Back15, Back16, Back18,
                            Back19, Back20]
    const [backgroundImage, setBackgroundImage] = useState("");

    useEffect(() => {
        const randomImage = images[Math.floor(Math.random() * images.length)];
        setBackgroundImage(randomImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container fluid className="homepage" style={{backgroundImage: `url(${backgroundImage})`}}>
            <Row>
                <h1 className="homepage-title">{t('title')}</h1>
            </Row>
            <Row>
                <p className="homepage-text">
                    {t('description')}
                </p>
                <p className="homepage-text">
                    {t('affiliation')}
                </p>
                <p className="homepage-text">
                    {t('build', {"build": build_count})}
                </p>
            </Row>
            <Row>
                <p className="homepage-bottom-text">
                    <span className={"homepage-text"}>
                        {t('work_in_progress')}
                    </span>
                    <br/>
                    <span className={"homepage-text"}>
                        {t('discord')}
                    </span>
                    <br/>
                    <Button href={discord_link} target="_blank" className="homepage-discord-invite-button">
                        <i className="fab fa-discord"></i>&nbsp; {t('join_discord')}
                    </Button>
                </p>
            </Row>
        </Container>
    );
}

export default Homepage
