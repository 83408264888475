import React from "react";
import {Col, Row} from "react-bootstrap";
import "./../components/card.css";

export function num_input(title: string, value: number, handler: (e: React.ChangeEvent<HTMLInputElement>) => void) {
    return <Row className={"card-attribute"}>
        <Col xs={8}>
            <div className={"card-attribute-text"}>{title}</div>
        </Col>
        <Col xs={4}>
            <input className={"card-attribute-input"} type="number" value={value} onChange={e => handler(e)} />
        </Col>
    </Row>
}
