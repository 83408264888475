import {useTranslation} from "react-i18next";
import {big_generator, tier1_generator, tier2_generator, tier3_generator} from "../../../calculators/values/gen_constants";
import ValueTable from "./value_table";
import React from "react";

function GenValueTable() {
    const { t } = useTranslation('values');

    const generators_headers = [
        t("generators.generators"),
        t("generators.power"),
        t("generators.heat"),
        t("generators.can_use_enhancers")
    ];

    const generators_data = [
        [t("generators.generator_tier_1"), tier1_generator.power, tier1_generator.heat, t("yes")],
        [t("generators.generator_tier_2"), tier2_generator.power, tier2_generator.heat, t("yes")],
        [t("generators.generator_tier_3"), tier3_generator.power, tier3_generator.heat, t("yes")],
        [t("generators.big_generator"), big_generator.power, big_generator.heat, t("no")],
    ];

    return <ValueTable
        title={t("generators.generators")}
        headers={generators_headers}
        data={generators_data}
    />
}

export default GenValueTable;
