import {Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

function KnownIssues() {
    const { t } = useTranslation('known_issues');
    const resources = i18n.getResourceBundle(i18n.language, 'known_issues');
    const issues = resources?.issues || {};
    const IssuesKeys = Object.keys(issues);

    function printIssue(key: string) {
        return (
            <li key={key}>
                {t(`issues.${key}`)}
            </li>
        );
    }

    return  <Col md={4}>
        <h3 className={"changelog-title"}>{t('title')}</h3>
        {
            (IssuesKeys.length !== 0) ?
                <ul>
                    {IssuesKeys.map((key) => printIssue(key))}
                </ul> :
                <ul>
                    <li>
                        {t('no-issues')}
                    </li>
                </ul>
        }
    </Col>
}

export default KnownIssues;
