import { useState } from 'react';

export function useLocalStorage(key: string, initialValue: any) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            if (item) {
                return JSON.parse(item);
            }
            return initialValue;
        } catch (error) {
            console.log("Error parsing localStorage item:", error);
            console.log("expecting a json, got ( type ) : ", typeof(window.localStorage.getItem(key)))
            console.log("expecting a json, got ( content ) : ", window.localStorage.getItem(key))
            window.localStorage.removeItem(key);
            return initialValue;
        }
    });

    const setValue = (value: any) => {
        try {
            setStoredValue(value);
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.log("Error setting localStorage item:", error);
        }
    };

    return [storedValue, setValue];
}
