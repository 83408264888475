import {heat_tokens} from "../../../calculators/values/gen_constants";
import {useTranslation} from "react-i18next";
import ValueTable from "./value_table";
import React from "react";

function HeatTokensValueTable() {
    const { t } = useTranslation('values');

    const heat_token_headers = [
        t("tokens.heat_tokens"),
        t("tokens.heat")
    ];

    const heat_token_data = Object.entries(heat_tokens).map(([key, value]) => [key, value]);

    return <ValueTable
        title={t("tokens.heat_tokens")}
        headers={heat_token_headers}
        data={heat_token_data}
    />
}

export default HeatTokensValueTable;
