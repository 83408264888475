import {useTranslation} from "react-i18next";
import getNewResourceProp from "../../../calculators/values/ressources";
import {resourcesValueProp} from "./resource_value_prop";
import {
    get_exorium_fuel_tank,
    get_exorium_processing_unit,
    get_fast_travel_core_module,
    get_fast_travel_propellant_tank,
    get_generator_module,
    get_reconstruction_machine,
    get_shield_generator_module
} from "../../../calculators/capships/resources/capship_component_resources";
import ResourceTable from "./resource_table";
import {
    get_thruster_module_frame,
    get_thruster_module_nozzle,
    get_thruster_module_nozzle_ring,
    get_thruster_module_nozzle_support
} from "../../../calculators/capships/resources/capship_thruster_resources";
import {
    get_capship_factory_hall_corner_beam,
    get_capship_factory_hall_edge_beam,
    get_capship_hall_corner_beam,
    get_capship_hall_edge_beam,
    get_capship_hangar_hall_corner_beam,
    get_capship_hangar_hall_edge_beam
} from "../../../calculators/capships/resources/capship_halls_resources";
import {
    get_capship_cube,
    get_capship_cube_wall_v1,
    get_capship_frame_long,
    get_capship_frame_medium
} from "../../../calculators/capships/resources/capship_structure_resources";

function CapShipPriceTableValue() {
    const { t } = useTranslation('values');
    const { t:u } = useTranslation('cap_ship_calc');

    const cap_ship_price_headers = [t('component'), ...Object.entries(getNewResourceProp()).map(([key, value]) => key)];

    const cap_ship_price_data: resourcesValueProp[] = [
        {name: u('exoriumProcessingUnitCount'), value: get_exorium_processing_unit()},
        {name: u('exoriumFuelTankCount'), value: get_exorium_fuel_tank()},
        {name: u('fastTravelCoreModuleCount'), value: get_fast_travel_core_module()},
        {name: u('fastTravelPropellantTankCount'), value: get_fast_travel_propellant_tank()},
        {name: u('reconstructionMachineCount'), value: get_reconstruction_machine()},
        {name: u('shieldGeneratorCount'), value: get_shield_generator_module()},
        {name: u('generatorModuleCount'), value: get_generator_module()},
        {name: u('thrusterModuleFrameCount'), value: get_thruster_module_frame()},
        {name: u('thrusterModuleNozzleCount'), value: get_thruster_module_nozzle()},
        {name: u('thrusterModuleNozzleRingCount'), value: get_thruster_module_nozzle_ring()},
        {name: u('thrusterModuleNozzleSupportCount'), value: get_thruster_module_nozzle_support()},
        {name: u('capShipHallCornerCount'), value: get_capship_factory_hall_corner_beam()},
        {name: u('capShipHallEdgeCount'), value: get_capship_factory_hall_edge_beam()},
        {name: u('capShipShipDesignerCornerCount'), value: get_capship_hall_corner_beam()},
        {name: u('capShipShipDesignerEdgeCount'), value: get_capship_hall_edge_beam()},
        {name: u('capShipFactoryHallCornerCount'), value: get_capship_hangar_hall_corner_beam()},
        {name: u('capShipFactoryHallEdgeCount'), value: get_capship_hangar_hall_edge_beam()},
        {name: u('cubeCount'), value: get_capship_cube()},
        {name: u('wallCount'), value: get_capship_cube_wall_v1()},
        {name: u('longFrameCount'), value: get_capship_frame_long()},
        {name: u('mediumFrameCount'), value: get_capship_frame_medium()}
    ]

    return <ResourceTable
        title={t("cap_ship_cost.cap_ship_cost")}
        headers={cap_ship_price_headers}
        data={cap_ship_price_data}/>
}

export default CapShipPriceTableValue;
