// global market constants
export const value_replace_zero = true
export const value_zero_replacement = "/"
export const market_no_longer_for_sale_metric = "minutes"
export const market_no_longer_for_sale_value = 2
// single resource constants
export const existing_markets: string[] = ["origin"]
export const error_market_name = "error"
export const error_resource_name = "error"
export const default_market_candlestick_time_frame = "4 hours"
export const default_market_candlestick_quantity = 150
export const default_graph_min_value = Number.MAX_VALUE
export const market_resource_small_padding_value = 0.1
