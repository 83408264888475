import {useTranslation} from "react-i18next";
import {auto_cannon, laser_cannon, plasma_cannon, rail_cannon} from "../../../calculators/values/weapon_constants";
import ValueTable from "./value_table";
import React from "react";

function WeaponsValueTable() {
    const { t } = useTranslation('values');

    const weapons_headers = [
        t("weapons.weapon"),
        t("weapons.fire_rate"),
        t("weapons.electricity_per_shot"),
        t("weapons.heat_per_shot"),
        t("weapons.heat_dissipation"),
        t("weapons.idle_elec_usage"),
    ];

    const weapons_data = [
        [t("weapons.auto_cannon"), auto_cannon.fire_rate_minute, auto_cannon.electricity_per_shot, auto_cannon.heat_per_shot, auto_cannon.heat_dissipation, auto_cannon.idle_electricity_consumption],
        [t("weapons.laser_cannon"), laser_cannon.fire_rate_minute, laser_cannon.electricity_per_shot, laser_cannon.heat_per_shot, laser_cannon.heat_dissipation, laser_cannon.idle_electricity_consumption],
        [t("weapons.plasma_cannon"), plasma_cannon.fire_rate_minute, plasma_cannon.electricity_per_shot, plasma_cannon.heat_per_shot, plasma_cannon.heat_dissipation, plasma_cannon.idle_electricity_consumption],
        [t("weapons.rail_cannon"), rail_cannon.fire_rate_minute, rail_cannon.electricity_per_shot, rail_cannon.heat_per_shot, rail_cannon.heat_dissipation, rail_cannon.idle_electricity_consumption],
    ]

    return <ValueTable
        title={t("weapons.weapons")}
        headers={weapons_headers}
        data={weapons_data}
    />
}

export default WeaponsValueTable;
