import {useTranslation} from "react-i18next";
import {big_propellant, medium_propellant, small_propellant} from "../../../calculators/values/propellant_constants";
import ValueTable from "./value_table";
import React from "react";


function PropellantValueTable() {
    const { t } = useTranslation('values');

    const propellant_tanks_headers = [
        t("propellant.propellant_tank"),
        t("propellant.propellant_volume"),
    ];

    const propellant_tanks_data = [
        [t("propellant.small_propellant_tank"), small_propellant],
        [t("propellant.medium_propellant_tank"), medium_propellant],
        [t("propellant.large_propellant_tank"), big_propellant],
    ];

    return <ValueTable
        title={t("propellant.propellant")}
        headers={propellant_tanks_headers}
        data={propellant_tanks_data}
    />
}

export default PropellantValueTable;
