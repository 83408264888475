import {Col, Container, Row} from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import {useTheme} from "../../theme/theme_context";
import {useEffect, useState} from "react";
import {backend_url} from "../../global_values";
import {MarketSeriesInterface} from "../market_datachart_interface";
import Dropdown from 'react-bootstrap/Dropdown';
import {useParams} from "react-router";
import {
    default_graph_min_value,
    default_market_candlestick_quantity,
    default_market_candlestick_time_frame,
    error_market_name,
    error_resource_name,
    existing_markets, market_resource_small_padding_value
} from "../const_values";
import {all_resources} from "../../calculators/values/armor_constants";
import {capitalizeFirstLetter} from "../../../utilities/strings";
import "../market.css"
import {useTranslation} from "react-i18next";
import {ResourceMarketDataItem} from "./resource_market_data_item";

function get_market_name(market_params_value: string|undefined) {
    if (market_params_value === undefined) {
        return error_market_name;
    }
    if (existing_markets.includes(market_params_value)) {
        return market_params_value;
    }
    return error_market_name;
}

function get_resource_name(resource_params_value: string|undefined) {
    if (resource_params_value === undefined) {
        return error_resource_name;
    }
    const capitalised = capitalizeFirstLetter(resource_params_value);
    if (all_resources.some((material) => material.name === capitalised)) {
        return resource_params_value;
    }
    return error_resource_name;
}

function ResourceMarket() {
    const { t } = useTranslation('market');

    const { theme } = useTheme();
    const resource_name_params = useParams().resource_name;
    const market_name_params = useParams().market_name;
    const market_name = get_market_name(market_name_params);
    const resource_name = get_resource_name(resource_name_params);

    const [rawData, setRawData] = useState<ResourceMarketDataItem[]>([]);
    const [displayedData, setDisplayedData] = useState<MarketSeriesInterface[]>([]);
    const [minValue, setMinValue] = useState(default_graph_min_value);
    const [timeFrame, setTimeFrame] = useState(default_market_candlestick_time_frame);
    const [quantity, setQuantity] = useState(default_market_candlestick_quantity)

    useEffect(() => {
        if(resource_name !== error_resource_name && market_name !== error_market_name) {
            fetch(backend_url + "/" + market_name + "/" + resource_name + "?time_frame=" + encodeURIComponent(timeFrame))
                .then(response => response.json())
                .then(fetchedData => {
                    const buffer: ResourceMarketDataItem[] = fetchedData.map((item: any) => ({
                        x: new Date(parseInt(item.date) * 1000),
                        y: [item.open, item.high, item.low, item.close] as [number, number, number, number]
                    }));
                    setRawData(buffer);
                })
                .catch(error => console.error('Error:', error));
        }
    }, [timeFrame, resource_name, market_name]);

    useEffect(() => {
        const slicedData = rawData.slice(-quantity);
        const toInsert = [{
            data: slicedData,
        }];
        setDisplayedData(toInsert);
        // compute a small padding for the y-axis based on the data
        if (slicedData.length > 0) {
            let min_value = slicedData[0].y[2]; // Initialize with the low of the first item
            let max_value = slicedData[0].y[1]; // Initialize with the high of the first item
            for (let i = 1; i < slicedData.length; i++) {
                const item = slicedData[i];
                const low = item.y[2];
                const high = item.y[1];
                if (low < min_value){
                    min_value = low;
                }
                if (high > max_value) {
                    max_value = high;
                }
            }
            let padding = (max_value - min_value) * market_resource_small_padding_value;
            setMinValue(min_value - padding);
        }
    }, [rawData, quantity]);

    let options: ApexOptions = {
        chart: {
            type: 'candlestick',
            height: 350
        },
        grid: {
            yaxis: {
                lines: {
                    show: false, // Disable horizontal grid lines
                },
            },
        },
        title: {
            text: 'CandleStick Chart',
            align: 'left',
            style: {
                color: theme === "dark" ? "#ffffff" : "#000000"
            }
        },
        xaxis: {
            type: 'datetime',
            title: {
                style: {
                    color: theme === "dark" ? "#ffffff" : "#000000"
                }
            },
            labels: {
                style: {
                    colors: theme === "dark" ? "#ffffff" : "#000000"
                }
            },
        },
        yaxis: {
            title: {
                style: {
                    color: theme === 'dark' ? '#ffffff' : '#000000',
                },
            },
            labels: {
                style: {
                    colors: theme === 'dark' ? '#ffffff' : '#000000',
                },
                formatter: function (value) {
                    return Math.round(value).toString();
                },
            },
            tooltip: {
                enabled: true,
            },
            min: minValue,
        },
        tooltip: {
            theme: theme,
        }
    }

    function display_error() {
        return (
            <Row>
                <p>
                    {
                        resource_name === error_resource_name
                        ? t('errors.resource_not_found', {'resource': resource_name})
                        : ""
                    }
                </p>
                <p>
                    {
                        market_name === error_market_name
                        ? t('errors.market_not_found', {'market': market_name})
                        : ""
                   }
                </p>
            </Row>
        );
    }

    function display_market() {
        return <>
            <Row>
                <h1>{t('resource.r_at_m', {'resource': capitalizeFirstLetter(resource_name), 'market': capitalizeFirstLetter(market_name)})}</h1>
            </Row>
            <Row>
                <Col md={6}>
                    <Container>
                        <Row className={"market-text-align-center market-small-padding-under"}>
                            <h2>
                                {t('menu.settings')}
                            </h2>
                        </Row>
                        <Row>
                            <Col md={6}>
                                {t('menu.candlestick_change')}
                            </Col>
                            <Col md={6}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        timeframe : {timeFrame}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setTimeFrame("15 minutes")}>{t('menu.timeframe.15_min')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setTimeFrame("30 minutes")}>{t('menu.timeframe.30_min')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setTimeFrame("1 hour")}>{t('menu.timeframe.1_hour')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setTimeFrame("4 hours")}>{t('menu.timeframe.4_hour')}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setTimeFrame("1 day")}>{t('menu.timeframe.1_day')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row className={"market-small-padding-under"}>
                            <Col md={6}>
                                {t('menu.candlestick_amount')}
                            </Col>
                            <Col md={6}>
                                <input type="number" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))}/>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={6}>
                    <Container>
                        <Row className={"market-text-align-center market-small-padding-under"}>
                            <h2>
                                {t('menu.current_values')}
                            </h2>
                        </Row>
                        {/*<Row>*/}
                        {/*    Current price:*/}
                        {/*</Row>*/}
                        {/*<Row>*/}
                        {/*    Current quantity: {quantity}*/}
                        {/*</Row>*/}
                    </Container>
                </Col>
            </Row>
            <Row>
                <ReactApexChart options={options} series={displayedData} type="candlestick" height={350} />
            </Row>
        </>
    }

    return (
        <Container>
            {
                resource_name === error_resource_name || market_name === error_market_name
                ? display_error()
                : display_market()
            }
        </Container>
    );
}

export default ResourceMarket;
