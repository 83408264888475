import {heat_sink_transfer, radiator_base_dissipation} from "../../../calculators/values/misc_constants";
import {useTranslation} from "react-i18next";
import ValueTable from "./value_table";
import React from "react";


function HeatDissipationValueTable() {
    const { t } = useTranslation('values');

    const heat_dissipation_headers = [
        t("heat_dissipation.component"),
        t("heat_dissipation.heat_transfer"),
        t("heat_dissipation.heat_dissipation"),
    ];

    const heat_dissipation_data = [
        [t("heat_dissipation.heatsink"), heat_sink_transfer, 0],
        [t("heat_dissipation.radiator_base"), 0, radiator_base_dissipation],
    ];

    return <ValueTable
        title={t("heat_dissipation.heat_dissipation")}
        headers={heat_dissipation_headers}
        data={heat_dissipation_data}
    />
}

export default HeatDissipationValueTable;
