import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

function RangeSlider (
    {
        minRange,
        maxRange,
        min,
        setMin,
        max,
        setMax,
    } : {
        minRange: number,
        maxRange: number,
        min: number,
        setMin: React.Dispatch<React.SetStateAction<number>>,
        max: number,
        setMax: React.Dispatch<React.SetStateAction<number>>,
    }) {

    const handleSliderChange = (value: number | number[]) => {
        if (Array.isArray(value)) {
            setMin(value[0]);
            setMax(value[1]);
        }
    }

    return (
        <Slider
            range
            min={minRange}
            max={maxRange}
            step={0.1}
            value={[min, max]}
            onChange={handleSliderChange}
        />
    )
}

export default RangeSlider;
