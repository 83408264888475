import {useTranslation} from "react-i18next";
import {Col} from "react-bootstrap";
import Paragprah from "../../utilities/paragraph";
import i18n from "i18next";

function Changelog() {
    const {t} = useTranslation('changelog');
    const resources = i18n.getResourceBundle(i18n.language, 'changelog');
    const versions = resources?.versions || {};
    const VersionsKeys = Object.keys(versions).reverse();

    function printChangelog(key: string) {
        return (
            <li key={key}>
                <div className={"changelog-bold"}>
                    {t(`versions.${key}.version`)} - {t(`versions.${key}.date`)}<br/>
                </div>
                <Paragprah text={t(`versions.${key}.changes`)}/>
            </li>
        )
    }

    return <Col md={4}>
        <h3 className={"changelog-title"}>{t('changelog')}</h3>
        <ul>
            {
              VersionsKeys.map((key) => printChangelog(key))
            }
        </ul>
    </Col>
}

export default Changelog
