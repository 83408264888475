import "./footer.css";
import "../../dark_mode.css"
import {Container, Image, Nav, Row, Col} from "react-bootstrap";
import EnImg from "../../assets/img/flags/en_flag.png";
import FrImg from "../../assets/img/flags/fr_flag.png";
import {useTranslation} from "react-i18next";
import {creator_name, logo_attribution_url} from "../global_values";

function Footer() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t, i18n } = useTranslation('footer');
    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    return <footer className={"footer-container"}>
        <Container className="footer">
            <Row className={"footer-content"}>
                <Col md={8} xs={9} className={"footer-text-container"}>
                    <p className={"footer-text"}>{t('footer', {"name": creator_name})}<a href={logo_attribution_url} title="calculator icons">Freepik</a></p>
                </Col>
                <Col md={4} xs={3}>
                    <Nav className="ml-auto social-links">
                    <div className="social-links">
                            <Image onClick={() => changeLanguage('en')} src={EnImg} />
                            <Image onClick={() => changeLanguage('fr')} src={FrImg} />
                        </div>
                    </Nav>
                </Col>
            </Row>
        </Container>
    </footer>
}

export default Footer;
