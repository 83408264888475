import {useTranslation} from "react-i18next";
import {Col} from "react-bootstrap";
import i18n from "i18next";

function Todolist() {
    const {t} = useTranslation('todolist');
    const resources = i18n.getResourceBundle(i18n.language, 'todolist');
    const tasks = resources?.tasks || {};
    const taskKeys = Object.keys(tasks);

    function printTodo(key: string) {
        return (
            <li key={key}>
                {t(`tasks.${key}`)}
            </li>
        );
    }

    return (
        <Col md={4}>
            <h3 className="changelog-title">{t('title')}</h3>
            {
                (taskKeys.length !== 0) ?
                    <ul>
                        {taskKeys.map((key) => printTodo(key))}
                    </ul> :
                    <ul>
                        <li>
                            {t('no-tasks')}
                        </li>
                    </ul>
            }
        </Col>
    );
}

export default Todolist;
