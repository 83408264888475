import {useTranslation} from "react-i18next";
import {
    collector_elec_per_sec,
    mining_laser_elec_per_sec,
    towing_elec_per_ton
} from "../../../calculators/values/tools_constants";
import ValueTable from "./value_table";
import React from "react";

function ToolsValueTable() {
    const { t } = useTranslation('values');

    const tools_headers = [
        t("tools.tool"),
        t("tools.electricity_per_second"),
    ];

    const tools_data = [
        [t("tools.mining_laser"), mining_laser_elec_per_sec],
        [t("tools.collector"), collector_elec_per_sec],
        [t("tools.towing_beam"), towing_elec_per_ton + " " + t("tools.elec_per_ton_per_second")],
    ];

    return <ValueTable
        title={t("tools.tools")}
        headers={tools_headers}
        data={tools_data}
    />
}

export default ToolsValueTable;
