import "./values.css";
import {Button, Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import React from "react";
import Paragprah from "../../utilities/paragraph";
import {discord_link} from "../global_values";
import GenValueTable from "./value_tables/calculator_tables/gen_value_table";
import FuelChamberValueTable from "./value_tables/calculator_tables/fuel_chamber_value_table";
import EnhancersValueTable from "./value_tables/calculator_tables/enhancers_value_table";
import ElectricityTokensValueTable from "./value_tables/calculator_tables/electricity_tokens_value_table";
import HeatTokensValueTable from "./value_tables/calculator_tables/heat_tokens_value_table";
import HeatDissipationValueTable from "./value_tables/calculator_tables/heat_dissipation_value_table";
import ThrustersValueTable from "./value_tables/calculator_tables/thrusters_value_table";
import ConvertersValueTable from "./value_tables/calculator_tables/converters_value_table";
import PropellantValueTable from "./value_tables/calculator_tables/propellant_value_table";
import WeaponsValueTable from "./value_tables/calculator_tables/weapons_value_table";
import ToolsValueTable from "./value_tables/calculator_tables/tools_value_table";
import ElectricityValueTable from "./value_tables/calculator_tables/electricity_value_table";
import CapShipPriceTableValue from "./value_tables/resource_tables/cap_ship_price_table_value";
import ArmorValueTable from "./value_tables/calculator_tables/armor_value_table";
import AlloyValueTable from "./value_tables/calculator_tables/alloy_values";

function Values() {
    const { t } = useTranslation('values');

    return <Container className={"values_container"}>
        <h3 className={"values-title"}>{t('title')}</h3>
        <Row className={"values-row"}>
            <Paragprah text={t("presentation")}/>
            <Button href={discord_link} target="_blank" className="homepage-discord-invite-button values-discord-button">
                <i className="fab fa-discord"></i>&nbsp; {t('join_discord')}
            </Button>
            <Paragprah text={t("table_description")}/>
        </Row>
        <Row className={"values-row"}>
            <Col md={6}>
                <GenValueTable/>
            </Col>
            <Col md={6}>
                <FuelChamberValueTable/>
            </Col>
        </Row>
        <Row className={"values-row"}>
            <Col md={4}>
                <EnhancersValueTable/>
                <ElectricityTokensValueTable/>
            </Col>
            <Col md={4}>
                <HeatTokensValueTable/>
            </Col>
            <Col md={4}>
                <HeatDissipationValueTable/>
            </Col>
        </Row>
        <Row className={"values-row"}>
            <Col md={6}>
                <ThrustersValueTable/>
            </Col>
            <Col md={6}>
                <ConvertersValueTable/>
                <PropellantValueTable/>
            </Col>
        </Row>
        <Row className={"values-row"}>
            <Col md={6}>
                <WeaponsValueTable/>
            </Col>
            <Col md={6}>
                <ToolsValueTable/>
                <ElectricityValueTable/>
            </Col>
        </Row>
        <Row className={"values-row"}>
            <CapShipPriceTableValue/>
        </Row>
        <Row className={"values-row"}>
            <Col lg={6}>
                <ArmorValueTable/>
            </Col>
            <Col lg={6}>
                <AlloyValueTable/>
            </Col>
        </Row>
    </Container>
}

export default Values;
