import "./paragraph.css"
import React from "react";

function Paragprah(
    {
        text
    }:{
        text: string
    }) {
    let paragraphs = text.split('\n');
    return <div>
        {paragraphs.map((paragraph, idx) => {
            return <div className={"small-paragraph-margin"} key={idx}>{paragraph}<br/></div>
        })}
    </div>
}

export default Paragprah;
