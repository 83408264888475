import "./chanlegog.css"
import {Container, Row} from "react-bootstrap";
import Changelog from "./changelog";
import Todolist from "./todolist";
import KnownIssues from "./known_issues";

function ChangelogPage() {
    return (
        <Container className="changelog">
            <Row>
                <Changelog/>
                <Todolist/>
                <KnownIssues/>
            </Row>
        </Container>
    )
}

export default ChangelogPage
